// import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'
import Setting from '@/views/setting/index.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/:pathMatch(.*)',
    redirect: '/'
  },
  {
    path: '/',
    name: 'Index',
    component: () => import('@/views/index/index.vue')
  },
  {
    path: '/test',
    name: 'Test',
    component: () => import('@/views/AboutView.vue')
  },
  {
    path: '/category',
    name: 'Category',
    component: () => import('@/views/category/category.vue')
  },
  {
    path: '/newslist',
    name: 'newsList',
    component: () => import('@/views/news/list.vue')
  },
  {
    path: '/newsdetail',
    name: 'newsDetail',
    component: () => import('@/views/news/detail.vue')
  },
  {
    path: '/help-center',
    name: 'helpCenter',
    component: () => import('@/views/help/help.vue')
  },
  {
    path: '/',
    name: 'Setting',
    component: Setting,
    children: [
      {
        path: '/set/about-us',
        name: 'aboutUs',
        component: () => import('@/views/setting/about-us.vue'),
      },
      {
        path: '/set/merchant',
        name: 'Merchant',
        component: () => import('@/views/setting/merchant.vue'),
      },
      {
        path: '/set/question',
        name: 'Question',
        component: () => import('@/views/setting/question.vue'),
      },
      {
        path: '/set/step',
        name: 'Step',
        component: () => import('@/views/setting/step.vue'),
      },
      {
        path: '/set/protocol',
        name: 'Protocol',
        component: () => import('@/views/setting/protocol.vue'),
      },
      {
        path: '/set/telephone',
        name: 'Telephone',
        component: () => import('@/views/setting/telephone.vue'),
      }
    ]
  }
]

// const router = createRouter({
//   history: createWebHistory(process.env.BASE_URL),
//   routes
// })

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes
})

export default router
