import axios from 'axios'
import { ElMessageBox } from 'element-plus'
import 'element-plus/theme-chalk/el-message-box.css'

let baseURL = ''
if(process.env.NODE_ENV === 'development'){
  baseURL = '/api'
}else if(process.env.NODE_ENV === 'production'){
  baseURL = 'https://apiwebman.szwxzw.cn'
}

const request = axios.create({
  baseURL,
})

// 请求拦截器
request.interceptors.request.use(function (config) {
  return config
})
// 响应拦截器
request.interceptors.response.use(function (response) {
  // 对响应数据做处理
  let code:number = response.data.code
  if(code && code != 200){
    ElMessageBox.alert('系统更新，请稍后再试', '提示')
  }else{
    return response.data
  }
}, async error => {
  return Promise.reject(error)
})

export default request
