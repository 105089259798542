import request from '../utils/request'
import {ParamsIndex, ParamsSelect, ParamsCate, ParamsChoose, ParamsNewList, ParamsNewDetail, ParamsHelp} from '../types/params'

// 获取首页数据
export const getIndexData = (data:ParamsIndex) => {
  return request({
    method: 'POST',
    url: '/v10/interface/inter_spec/allBanner',
    data
  })
}

// 首页分类筛选数据
export const selectCate = (data:ParamsSelect) => {
  return request({
    method: 'POST',
    url: '/v10/interface/inter_spec/hot',
    data
  })
}

// 获取分类页面数据
export const getCategory = (data:ParamsCate) => {
  return request({
    method: 'POST',
    url: '/v10/interface/inter_spec/category',
    data
  })
}

// 获取分类页面筛选数据
export const selectCateProduct = (data:ParamsChoose) => {
  return request({
    method: 'POST',
    url: '/v10/interface/inter_spec/specialty',
    data
  })
}

// 获取新闻列表
export const getNewsList = (data:ParamsNewList) => {
  return request({
    method: 'POST',
    url: '/v10/interface/inter_spec/newsList',
    data
  })
}

// 获取新闻详情
export const getNewDetail = (data:ParamsNewDetail) => {
  return request({
    method: 'POST',
    url: '/v10/interface/inter_spec/news',
    data
  })
}

// 帮助中心类目名称获取
export const getHelpList = (data:ParamsHelp) => {
  return request({
    method: 'POST',
    url: '/v10/interface/inter_spec/helpList',
    data
  })
}
