import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-bd86d10a"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "container"
};
import { ref, reactive } from 'vue';
import { getHelpList } from '../../api/api';
export default {
  __name: 'index',
  setup(__props) {
    let statusColor = ref('about-us');
    let leftNavList = reactive({
      list: []
    });
    let childClick = status => {
      statusColor.value = status;
    };
    let onPageData = async () => {
      let {
        data
      } = await getHelpList({
        tag: 'helpList',
        ds: 4,
        articleId: 32,
        isHome: 1
      });
      leftNavList.list = data.articleCate;
    };
    onPageData();
    return (_ctx, _cache) => {
      const _component_el_link = _resolveComponent("el-link");
      const _component_el_aside = _resolveComponent("el-aside");
      const _component_router_view = _resolveComponent("router-view");
      const _component_el_main = _resolveComponent("el-main");
      const _component_el_container = _resolveComponent("el-container");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_el_container, null, {
        default: _withCtx(() => [_createVNode(_component_el_aside, {
          width: "226px"
        }, {
          default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(leftNavList).list, (item, index) => {
            return _openBlock(), _createElementBlock("div", {
              key: index
            }, [_createElementVNode("p", null, _toDisplayString(item.category_name), 1), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.articleList, (tab, okey) => {
              return _openBlock(), _createBlock(_component_el_link, {
                href: "/set/about-us",
                underline: false,
                class: _normalizeClass(_unref(statusColor) == 'about-us' ? 'blue_text' : ''),
                key: index
              }, {
                default: _withCtx(() => [_createTextVNode(_toDisplayString(tab.article_title), 1)]),
                _: 2
              }, 1032, ["class"]);
            }), 128))]);
          }), 128))]),
          _: 1
        }), _createVNode(_component_el_main, null, {
          default: _withCtx(() => [_createVNode(_component_router_view, {
            onChildClick: _unref(childClick)
          }, null, 8, ["onChildClick"])]),
          _: 1
        })]),
        _: 1
      })]);
    };
  }
};